/**
 * @Author: Chanaka Wickramasinghe
 * @Date: 2020-04-21 11:45:44
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2020-03-20 14:06:11
 */

.element-card {
  padding: 10px !important;
  margin-top: 10px;
  margin-right: 8px;

  div {
      div {
          p {
              font-size: 13px;
          }
      }
  }
}

.element-card-header {
  margin-top: 5px;
  text-align: center;

  h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28.8px;
  }
}

.element-non-complience {
  margin-top: 27px;
  margin-bottom: 10px;

  p {
    font-style: italic;
    font-size: 15px;
    color: $nobel;
    font-weight: bold;
  }
}

.element-complience {
  margin-top: 20px;
  margin-bottom: 10px;

  p {
    font-style: italic;
    font-size: 15px;
    color: $nobel;
    font-weight: bold;
  }
}

.element-mandatory {
  p {
    color: $materialRed;
    font-weight: bold;
  }
}

.element-must {
  p {
    color: $dodgerBlue;
    font-weight: bold;
  }
}

.element-should {
  p {
    color: $materialGreen;
    font-weight: bold;
  }
}

@media screen and (max-width: $extraSmallDevice) {
  .element-card {
      margin-right: 0px !important;

      div {
          div {
              text-align: left !important;
          }
      }
  }
}

