/*
 * @Author: Chanaka Wickramasinghe
 * @Date: 2020-04-21 15:45:12
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2020-04-08 11:24:52
 */

.element-bottom-card {
  padding: 10px !important;
  margin-top: 10px;
  margin-right: 8px;
  min-height: 178px !important;
  max-height: 178px !important;
  overflow-y: auto !important;

  .dashboard-locations {

  }
}

.element-bottom-card-header {
  text-align: center;

  h3 {
      font-size: 15px;
      color: $nobel;
      font-weight: bold;

      span {
          font-size: 20px;
          margin-right: 5px;
          color: $nobel;
          font-weight: bold;
      }
  }
}

.element-bottom-card-section {
  text-align: end;
  margin-top: 20px;
  margin-right: 5px;

  h3 {
      font-size: 23px;
      color: $materialRed;
      font-weight: bold;
  }

  p {
      color: $nobel;
      font-weight: 400;
  }
}

@media screen and (max-width: $extraSmallDevice) {
  .element-bottom-card {
    margin-right: 0px !important;
  }
}
