/*
 * @Author: Chanaka Wickramasinghe
 * @Description: Rates styles
 * @Date: 2021-05-20 11:51:34
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2021-05-20 11:51:34
 */

.roundBtnContainer {
  position: relative;
}

.roundBtnVerticalCenter {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}