/*
 * @Author: Chanaka Wickramasinghe
 * @Description: RadioButton Styles
 * @Date: 2020-02-27 14:20:53
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-08 15:36:57
 */

// Material RadioButtonsGroup css class
.materialRadioBtnGrpWrapper {

    .radioBtnGrpLabel {
        font-size: $defaultLabel !important;
        color: $nobel !important;
        margin-bottom: 0px !important;

        span {
            color: $red;
        }
    }

    .errorTxt{
        color: $red  !important; 
    }

    .materialRadioGroupStyle {

        .defaultRadioLabel {

            margin-bottom: auto !important;

            span {
                font-size: $defaultFontSize !important;
                color: $darkGrey !important;
            }

            .defaultRadioButton {

                svg {
                    width: 17px !important;
                    height: 17px !important;
                }

            }
        }
    }

    .radioBtnGrpHelperText {
        margin-top: 4px !important;
    }
}