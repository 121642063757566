/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-06-01 11:03:15 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-06-01 16:51:42
 */

 .transferListMainWrapper{
    @extend .fullWidthDiv;

    .defaultInputLabel{
        float: left !important;
        width: 100%; 
        position: relative !important;   
    }
   

    .transferListSubWrapper{
        @extend .fullWidthDiv;
        @include displayFlex(); 
        @include flexDirection(row);
        .listItemWrapper{
           width:40%;
           .listWrapper{
               padding: 0px;
               max-height: 200px;
               overflow:auto;
               .itemWrapper{
                padding: 0px;
               }
           }
        }

        .buttonWrapper{       
          width: 20%;
          @include displayFlex(); 
          align-items: center; 
          

          .subBtnWrapper{
            margin-left: auto;
            margin-right: auto;
            @include displayFlex(); 
            @include flexDirection(column);
            .btn{
                background-color: $primaryColor;
                color:  $white;
                margin-bottom: 20px;                
            }

            .inactive{
                background-color: $geyserColor !important;
            }

          }
        }
    }
 }