/*
 * @Author: Chanaka Wickramasinghe
 * @Description: BaseElements styles
 * @Date: 2020-02-28 11:41:09
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-15 09:38:31
 */

//Card
.defaultCardWrapper{   
    box-shadow: 0 0 0 1px #e2e5e8 !important;
    border-radius: 0px !important;
    

    .cardHeaderWrapper{
        width: 100%;
        padding: 15px;
        border-bottom: 1px solid #e2e5e8;

        .headingTxt{
            @extend .defaultHeading;
        }
    }

    .cardBodyWrapper{
        padding: 15px;
        float: left;
        width: 100%;
    }
}

.defaultBGWrapper{
    background-color: $bodyColor;
    padding: $defaultPadding;
}

//Card

// BreadCrumb Wrapper

.defaulBreadCrumbWrapper{    
    margin-top: 3px !important;   
    
    .defaultLinkClass{
        color: #313131 !important;
        font-size: 14px;
        .defaultIconStyle{
            margin-right: 6px;
        }

        &:hover{
            text-decoration: none;
        }
    }

    .activeLink{
        color: #6c757d !important;
        font-size: 14px;
    }
}


// Material Icon css class
.defaultMaterialIcon {
    font-size: $defaultFontSize;
}

// Bootstrap Icon css class
.defaultBootstrapIcon {
    @extend .defaultMaterialIcon;
}

.defaultAppBarWrapper {
    width: 100%;
    float: left;
}

//PaddingLessUICard
.defaultPaddingLessUICardWrapper{
    @extend .fullWidthDiv;
    //background-color: blanchedalmond;
    .paddingLessHeaderWrapper{
        width: 100%;
        display: flex;
        flex-direction: row;
        .hedingWrapper{
            flex:1;
            //padding-top:5px;
            h5{
                @extend .defaultHeading;
                display: inline;
                background-color: $white;
                padding-right: 5px;
                            
            }
        }

        .tailWrapper{
            display: flex;
            flex:1;
            justify-content: flex-end;
            

            .tailComponentWrapper{
                background-color: $white;
                padding-left: 5px;
                button{
                    margin: 0px 2px 0px 2px !important;
                }

                .defaultCheckBoxWrapper{
                    margin-bottom: 0px !important;

                    .defaultCheckbox{
                        padding: 0px 2px 0px 2px !important;
                    }
                }


            }
        }

        
    }
    .underLine{
        @extend .fullWidthDiv;
        height: 1px;
        background-color: $secondaryColor;
        margin-top: -9px;
    }
    .paddingLessBodyWrapper{
        width: 100%;
        padding: 10px;
    }
}

//PaddingLessUICard



// ViewDetailBlock Component Styles

.default-ViewDetailBlock{
    .tittle-style{

    }
    .detail-style{
        
    }
}

.view-block-word-wrap-style {
    word-wrap: break-word !important;
}