.uploadMainWrapper{
    .image{
        box-shadow: 3px 2px 7px -1px rgba(68,102,242,.13);
        padding: 25px 0px 25px 0px;

        .passphoto { 
            font-family: 'Font Awesome 5 Free';
            font-size: 9em;
            display: inline-block;
            width: 200px;
            height: 234px;
            border: 1px solid $battleshipGreyColor;
        }
    }
}