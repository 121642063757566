/**
 * @Author: Chanaka Wickramasinghe
 * @Description: Form common styles
 * @Date: 2020-02-26 15:28:38
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-08 15:36:51
 */

// Default Label css
.defaultInputLabel {
    font-size: $defaultInputLabel !important;
    color: $nobel;
    line-height: 0.9 !important;
    span {
        color: $red;
    }
}

// Default Helper Text css
.defaultFormHelperText {
    font-size: $defaultHelperText !important;
    color: $nobel;
    margin-top: 0px !important;
    min-height: 18px;
}

// Default Input Wrapper css
.defaultInputWrapper {
    margin: 0px 0px 8px 0px !important;

    .defaultInputLabel {
        @extend .defaultInputLabel; 
    }

    .defaultFormHelperText{
        @extend .defaultFormHelperText; 
    }    
    
}

.defaultErrorInputWrapper{
    
    label {
        color: $red !important;
    }

    .bootstrapInputText, .bootstrapSelectWrapper {
        border: 1px solid $red !important;
    }
}