/*
 * @Author: Sujith
 * @Date: 2020-06-08 20:42:48
 * @Last Modified by: Sujith
 * @Last Modified time: 2020-02-28  20:42:48
 */

.defaulSnackBarWrapper{   
   
    .alertSize{
      
    }
    .successPopupCloseIcon {
        cursor: pointer;
        font-size: 20px;
        color: $successColor;
        margin-left: 260px;
    }
    .errorPopupCloseIcon {
        cursor: pointer;
        font-size: 20px;
        color: $materialRed;
        margin-left: 260px;
    }
}