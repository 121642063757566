/**
 * @Author: LahiruC
 * @Date: 2020-04-21 14:17:12
 * @Last Modified by: LahiruC
 * @Last Modified time: 2020-04-21 14:17:12
 */

.companyLogoThumbnail {
    border: 1px solid gray;
    border-radius: 4px;
    padding: 5px;
    width: 250px;
}