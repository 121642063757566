/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-03-19 12:10:04 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-05-13 14:14:52
 */

.templateOneWrapper{
   @extend .fullWidthDiv;
  

   .templateOneBodyWrapper{
      z-index: 10;
      @extend .fullWidthDiv;
      @include calc(min-height,'100vh - '+$appbarHeight);  
      margin-top: $appbarHeight;
        
         
      .subBodyWrapper{
         @extend .fullWidthDiv;
         padding: 25px;
         .breadCrumbWrapper{
            margin-bottom: 25px;
            .breadCrumbSubWrapper{
               .pageHeading{
                  @extend .floatLeft;
                  margin: 0px;
                  display:inline-block; 
                  font-size: 24px;
                  font-weight: 600;
                  margin-right: 30px;
               }
               .defaulBreadCrumbWrapper{
                  @extend .floatLeft;
               }
            }
         }
      } 
   }


   //mobile
   @media screen and (max-width: $mobileBreakPoint) {

      .fullBodyWrapper{
         padding-left: 0px;
         transition:padding-left 1s;  
      }
   
      .halfBodyWrapper{
         padding-left: 0px;  
         transition:padding-left 1s;  
      }

   }

   //desktop
   @media screen and (min-width: $mobileBreakPoint) { 

      .fullBodyWrapper{
         padding-left: 0px;
         transition:padding-left 1s;  
      }
   
      .halfBodyWrapper{
         padding-left: $menuBarWidth;  
         transition:padding-left 1s;  
      }

   }

}

.pageLoader{
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   background:rgba(0,0,0,0.5) !important;
   width: 100%;
   position: fixed;
   z-index: 99999999999999999;

   .loader {
      position: absolute;
      left: 50%;
      top: 45%;       
      color: $white !important;
   }
}


.bootstrapTemplateOneWrapper{
   .templateOneBodyWrapper{
      background-color: $bootstrapBodyColor;
   }
}

.materialTemplateOneWrapper{
   .templateOneBodyWrapper{
      background-color: $materialBodyColor;
   }
}



.initFullPageLoader{
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   background-color: $bodyColor;
   width: 100%;
   position: fixed;
   z-index: 99999999999999999;

   .loader {
      position: absolute;
      left: 50%;
      top: 45%;       
      color: $primaryColor !important;
   }
}