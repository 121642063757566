/*
 * @Author: Chanaka Wickramasinghe
 * @Description: CheckBox Styles
 * @Date: 2020-02-27 15:32:24
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-10-08 09:04:23
 */

// Material CheckBox css class
.defaultCheckBoxWrapper {
  
    width: auto !important;
    margin-bottom: 0px !important;
    margin-right: 10px !important;

    .defaultCheckBoxLabel {
        display: block;
        // padding-right: 10px;
         padding-left: 22px;
        text-indent: -30ypx;
        
        span {
            vertical-align: middle;
            font-size: $defaultFontSize !important;
            color: $darkGrey !important;
            
        }

        .defaultCheckbox {
            vertical-align: middle;
             padding: 5px !important;
            svg {
                width: 20px !important;
                height: 20px !important;
            }
        }

        margin-bottom:0px !important
    }

    .checkBoxWithOutLable{
        margin-right: 0px !important;
    }

    .defaultCheckBoxHelperText {
        margin-top: -5px !important;
    }
}