/**
 * @Author: Chanaka Wickramasinghe
 * @Date: 2020-07-07 11:05:32
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2020-07-07 11:05:32
 */

.view-certificate {
  margin-right: 5px !important;
  position: relative;

  img {
    height: 300px !important;
  }
}

.certificate-image-hover {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;

  i {
    font-size: 27px;
    cursor: pointer;
  }
}

.view-certificate:hover .certificate-image {
  opacity: 0.5;
}

.view-certificate:hover .certificate-image-hover {
  opacity: 1;
}
