/**
 * @Author: Sujith
 * @Date: 2020-03-12 10:18:12
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-03-20 14:09:58
 */


 .ForgotPasswordMainWrapper{
    @include displayFlex(); 
    background-color: gray;
    width: 100%;
    height: 100%;
    position: fixed;          
    align-items: center;
   


    .fgPasswordCard{
        @include boxShadow(0px 10px 25px 9px rgba(0,0,0,.2)); 
        margin-left: auto;margin-right: auto;
        background-color: $white;
        padding: 30px 20px;
        width: 500px;   
        
        h4{
            text-align: center;
            font-weight: 600;
            letter-spacing: 1px;
        }
        p{
            color:gray;
            font-size: $defaultFontSize;
            text-align: center;
        }   
    }
    .fgPasswordFormWrapper{
        margin-top: 40px;
    
        .btnWrapper{
            background-color: gray !important;
            color: $white;            
        }
    }
}
