/**
 * @Author: Chanaka Wickramasinghe
 * @Date: 2020-04-20 16:11:32
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2020-03-20 14:06:11
 */

.dashboard-btn-grp {
  display: inline-flex;
  border: 2px solid $primaryColor;
  border-radius: 5px;

  div {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.5s ease-out;

    h3 {
      font-size: $defaultFontSize;
      font-weight: 500;
      user-select: none;
      color: $nobel;
    }
  }

  div:hover {
    background-color: #143fef;

    h3 {
      color: $bodyColor;
    }
  }
}

.dashboard-btn-grp-active {
  background: #143fef;

  h3 {
    font-size: $defaultFontSize;
    font-weight: 500;
    color: $bodyColor !important;
  }
}

@media screen and (max-width: $extraSmallDevice) {
  .dashboard-btn-grp {
    //@include displayFlex();
    //@include flexDirection(column);
    display: none !important;
  }
}

