/*
 * @Author: Chanaka Wickramasinghe
 * @Description: DialogPopup styles
 * @Date: 2020-02-28 12:51:34
 * @Last Modified by: Pradeepa Sandaruwan
 * @Last Modified time: 2021-01-21 11:34:49
 */

.defaultDialogWrapper {

    z-index: 999999999999;
    @extend .defaultAppBarWrapper;

    .defaultAppBarWrapper{
        box-shadow: none !important;

        .popupToolbarStyle {
            padding-left: 0px;
            background-color: $secondaryColor !important;
            min-height: auto !important;
            padding: 15px !important;     

            .titleWrapper {

                width: 100%;
                float: left;
                h2{
                    color: $white;
                    font-size: 16px !important;
                    font-weight: 500;
                }     
                
            }


            .buttonWrapper {
                width: 50%;
                float: left;
                text-align: right;
            }
        }
    }



    .halfTitleWrapper {
        @extend .titleWrapper;

        h2 {
            color: $black !important;
            font-size: 18px !important;
        }
    }

 


    .fullScreenPopupCloseIcon {
        cursor: pointer;
        font-size: 20px;
    }

    .popupCloseIcon {
        margin-top: 15px;
        margin-right: 15px;
        cursor: pointer;
        font-size: 20px;
        color: $primaryColor;
    }

    .defaultContent {
        padding: $defaultPadding $defaultPadding $defaultPadding $defaultPadding !important;
        border-top: 1px solid  #e9ecef;
        min-width: 500px;
    }

    .fullScreenContent {
        padding: $defaultPadding !important;
    }

    .defaultDialogActionWrapper {
        border-top: 1px solid  #e9ecef;
        padding: $defaultPadding $defaultPadding $defaultPadding $defaultPadding;
    }
}
