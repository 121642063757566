/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-03-22 19:01:57 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-11-09 14:05:42
 */

.dataTableWrapper {
  @extend .fullWidthDiv;
  box-shadow: 0 0 0 1px #e2e5e8 !important;
  border-radius: 0px !important;
  background-color: $white;

  .dataTableHeader {
    @extend .fullWidthDiv;
    padding: $defaultPadding;
    border-bottom: 1px solid #e2e5e8;

    .dataTableHeaderTxtWrapper {
      h5 {
        color: #111;
        font-size: $defaultH3;
        font-weight: 500;
      }
    }
  }

  .dataTableSearchBoxWrapper {
    @extend .fullWidthDiv;
    padding: $defaultPadding;

    .buttonWrapper {
      .searchBtn {
        margin-right: 10px;
        text-align: right;
      }
    }
  }

  .dataTableBodyWrapper {
    @extend .fullWidthDiv;
    overflow-x: scroll;

    table {
      width: 100%;

      .dataTableCheckBox {
        padding: 0px !important;
      }

      .maxWidth {
        max-width: 350px;
        overflow-wrap: break-word;
      }

      .textAlignCenter {
        text-align: center !important;
      }

      thead {
        tr {
          th {
            padding: $defaultPadding;
            font-size: $dataTableTH;
            color: #111;
          }
        }

        border-bottom: 1px solid #b7b7b7;
      }

      tbody {
        tr {
          td {
            font-size: $defaultFontSize;
          }
        }


        .acctionBtnWrapper {

          .defaultActionBtn {
            padding: 5px 10px 5px 10px;
            border: none;
            background-color: $secondaryColor;
            font-size: $defaultFontSize;
            box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
              0px 2px 2px 0px rgba(0, 0, 0, 0.14),
              0px 1px 5px 0px rgba(0, 0, 0, 0.12);
            color: $white;
            margin: 2px 3px 2px 3px;
            float: left;
          }
        }

        .acctionBtnWrapper-large {
          display: flex;
          flex-direction: row;
        }

        .odd_row {
          border-bottom: 1px solid #f5f5f5;

          td {
            padding: 13px $defaultPadding;
          }
        }

        .even_row {
          padding: 0px !important;

          .tableBodyExpandWrapper {
            padding: 10px;
          }
        }

        .dataViewCard {
          width: 100%;
          float: left;
          padding: 5px 5px 5px 5px;

          strong {
            color: #111;
            font-size: $defaultFontSize;
            padding-right: 10px;
          }
        }

      }
    }
  }

  .dataTableFooterWrapper {
    @extend .fullWidthDiv;
    padding: 10px;

    .btnWrapper {
      display: flex;
      justify-content: flex-end;

      .defaultRoundBtn {
        margin: 0px 5px 0px 5px !important;
      }
    }
  }
}

.scrolTable {
  table {
    width: 1000px !important;
  }
}

.dataTableWrapper {
  .dataTableHeader {
    .btnWrapper {
      display: flex;
      justify-content: flex-end;

      .dataTableHeaderBtn {
        margin-left: 10px;
      }
    }
  }
}

//  .dataTableWrapper{
//     @extend .fullWidthDiv;

//     .dataTableHeader{
//         padding-bottom: 15px;

//         .headerWrapper{
//             h5{
//                 font-size: 18px;
//                 margin: 0px;
//                 margin-bottom: 10px;
//             }
//         }

//         .btnWrapper{
//             display: flex;
//             justify-content: flex-end;
//             .dataTableHeaderBtn{
//                 margin-left: 10px;

//             }
//         }

//     }

//     .dataTableSearchBoxWrapper{
//        .formWrapper{
//            padding: 0px 20px 20px 20px;
//            border-top: 2px solid #f5f5f5;
//            border-bottom: 2px solid #f5f5f5;

//            .buttonWrapper{
//                margin-top: 20px;

//                .searchBtn{
//                    margin-right: 10px;
//                }
//            }
//        }
//     }

//     .dataTableBodyWrapper{
//         @extend .fullWidthDiv;
//         overflow-x: scroll;
//         padding-bottom: 20px;

//         table{
//             width: 100%;
//             //min-width: 1000px;
//             thead{
//                 tr{
//                     border-bottom: 3px solid #f5f5f5;
//                     th{
//                         padding: 15px;
//                         cursor: pointer;
//                         color: rgb(120, 129, 149) !important;
//                         font-size: $defaultFontSize;

//                         .shortArrow{
//                             float: right;
//                             margin-top: 3px;
//                             color: rgb(120, 129, 149) !important;
//                         }
//                     }

//                 }
//             }

//             tbody{

//                 .odd_row{

//                     border-bottom: 1px solid #f5f5f5;
//                     &:hover{
//                         background-color: $bodyColor !important;

//                         .defaultActionBtn{
//                             background-color: $white;
//                             box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
//                         }
//                     }

//                     td{
//                         padding: 15px;

//                         .defaultActionBtn{
//                             color: $primaryColor;
//                             background-color: rgba(0, 0, 0, 0.12);
//                             border: none !important;
//                             padding: 5px 10px 5px 10px;
//                             margin: 0px 5px 0px 5px;
//                             min-width: 35px;
//                             border-radius: 3px;
//                             margin-bottom: 3px;
//                         }

//                     }

//                 }

//                 .even_row{
//                     padding: 0px !important;

//                     .tableBodyExpandWrapper{
//                         padding: 10px;
//                     }
//                 }

//                 .dataViewCard{
//                     width: 100%;
//                     float: left;
//                     padding: 5px 5px 5px 5px;

//                     strong{
//                         color: rgb(120, 129, 149) !important;
//                         font-size: $defaultFontSize;
//                         padding-right: 10px;
//                     }

//                 }

//             }
//         }

//     }

//     .dataTableFooterWrapper{
//         padding:15px 0px 15px 0px;
//         @extend .fullWidthDiv;
//         p {
//             color: #6c757d;
//         }

//         .btnWrapper{
//             display: flex;
//             justify-content: flex-end;
//             .defaultRoundBtn{
//                margin: 0px 10px 0px 10px;
//                box-shadow: none !important;
//             }

//         }
//     }
//  }