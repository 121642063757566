/*
 * @Author: Chanaka Wickramasinghe
 * @Description: Tabs styles
 * @Date: 2020-03-04 10:53:59
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-14 11:22:50
 */

 .defaultTabsWrapper {
    @extend .fullWidthDiv;

    .defaultAppBarWrapper{
       @extend .fullWidthDiv;
       background-color: $white;
       box-shadow: none !important;
       border-bottom: 1px solid #e2e5e8 !important;

       .defaultTab{
           min-height: 30px !important;
       }

       .defaultTabItemWrapper{
           min-height: auto !important;
           padding: 5px !important;
           span{
               color :$secondaryColor !important;
               font-size: 13px !important;
               text-transform: none !important;
           }
       }
    }

    .defaultTabItemBodyWrapper {
        @extend .fullWidthDiv;
        background-color: $white !important;
        padding: 10px;
    }
 }

// .defaultTabsWrapper {

//     .materialTabsWrapper {

//         .defaultAppBarWrapper {

//             //background-color: $white;
//             box-shadow: none;
//            // z-index: 10 !important;

//             .defaultTab {
//                 z-index: -1 !important;
//                 width: 100%;

//                 .defaultTabItemWrapper {

//                     //background-color: $white;  
//                     border-bottom: 1px solid $menuLinkLightGray;
//                     min-height: 30px !important;

//                     span {

//                         color: $white !important;
//                         text-transform: none !important;
//                         font-size: $defaultFontSize;
//                         font-weight: 500;
//                     }
//                 }

//                 .activeTab {

//                     span {
//                        color: $white !important;
//                     }
//                 }

//                 .MuiTabs-scrollButtons {
//                     //color: $primaryColor !important;
//                     svg{
//                         font-size: 30px;
//                         margin-bottom: -10px !important;
//                     }
//                 }
//             }
//         }                                                     
//     }

//     .defaultTabItemBodyWrapper {

//         background-color: $white !important;
//         padding: 10px !important;
//         float: left !important;
//         padding: 10px;
//         width: 100%;
//     }
// }
