.search-button-margin {
  margin-top: 12px;
}

.notificationMainWrapper {
  .notificationFrom {
    margin-bottom: 10px;
  }

  .notificationWrapper {
    @extend .fullWidthDiv;
    margin-bottom: 10px;
    cursor: pointer;

    .notificationContentWrapper {
      width: 80%;
      float: left;
      h1 {
        margin-bottom: 5px;
      }
    }
    .notificationTimeWrapper {
      width: 20%;
      float: left;
      text-align: right;
    }
  }

  .new {
    border: 1px solid $secondaryColor !important;

    .notificationTimeWrapper {
      .newIcon {
        color: $secondaryColor;
        font-size: 5px;
      }
    }
  }

  .seen {
    background-color: #f2f2f2 !important;
    
    .notificationTimeWrapper {
      .newIcon{
        display: none !important;
      }
      p {
        margin-top: 10px;
      }
    }
  }

  .footerWrapper {
    
  }
}
