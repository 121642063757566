/**
 * @Author: Chanaka Wickramasinghe
 * @Date: 2020-05-13 14:24:32
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2020-05-13 14:24:32
 */

.dashboard-skeleton-wrapper-top {
  padding-top: 10px !important;
  margin-right: 8px !important;

  span {
    width: auto !important;
  }
}

.dashboard-skeleton-wrapper-bottom {
  margin-right: 8px !important;

  span {
    width: auto !important;
  }
}
