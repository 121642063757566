/**
 * @Author: Chanaka Wickramasinghe
 * @Date: 2020-07-16 11:39:32
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2020-07-16 11:39:32
 */
.display-correction-evidence {
  text-align: center !important;

  div {
    display: inline-block !important;
    margin-bottom: 10px;

    img {
      height: 175px !important;
    }
  }
}
