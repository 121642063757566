/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-03-19 17:50:39 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2021-01-04 11:41:27
 */

.subMenuWrapper{
  
  .defaultListItemWrapper{
    padding-top:5px !important;
    padding-bottom:5px !important;
    
    .MuiListItemIcon-root{
      min-width: 20px !important ;
    }

    .defaultListItemIcon{
      color: $menuFontColor !important;
      font-size: 14px !important;      
    }

    .leftIcon{
      padding-right: 10px;
    }

    .defaultListItemText{    
      visibility: hidden;  
      span{
        color: $menuFontColor !important;
        font-size: 14px !important;
        &:hover{
          text-decoration:none !important;
        }
      }
    }  
    
    &:hover{
      .defaultListItemIcon{
        color: $secondaryColor !important;
      }
      .defaultListItemText{
        span{
          color: $secondaryColor !important;
        }
      }
    }
  }

  .menuActive {
    background-color: $secondaryColor !important;

    &:hover{
      .defaultListItemIcon{
        color: $menuFontColor !important;
      }
      .defaultListItemText{
        span{
          color: $menuFontColor !important;
        }
      }
    }
  }
}


.templateTwoSideMenuHide{

  .subMenuWrapper{
    .defaultListItemText{
      visibility: hidden;
      transition: visibility .3s;        
    }
  }
  
}

.templateTwoSideMenuShow{
  .subMenuWrapper{
    .defaultListItemText{
      visibility: visible;
      transition: visibility 1s;        
    }
  }
}