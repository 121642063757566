/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-09-08 16:21:26 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-16 11:03:43
 */

 .counterTemplateWrapper{
    @extend .fullWidthDiv;
    
    .counterTemplateHeaderWrapper{
        @extend .fullWidthDiv;
        display: flex;
        flex-direction: row;
        padding: 0px 10px 0px 10px;
        background-color: $white;
        box-shadow: 0 1px 0 0 #e2e5e8;


        .pageHeading{
            display: flex;
            flex:1;
            flex-direction: column;
            padding: 5px 0px 5px 0px;
            h1{
                color: #111;
                font-size: 16px;
                font-weight: 500;
            }

            h5{
                color: $otherLinkSeparator;
                font-size: $defaultFontSize;
                font-weight: 500;
            }
            
        }

        .pageHeadingLinkWrapper{
            flex: 1;
            display: flex;
            justify-content: flex-end;
            padding: 10px 0px 0px 0px;

            button{
                border: none;
                background-color: $white;
                margin:0px 10px 0px 10px;
                height: 24px;
                i{
                    font-size: 16px;
                }
            }

            .licenseSequenceInputBox{
                display: flex;
                flex-direction: row;
                
                button{
                    border: none;
                    background-color:#E0E0E0;
                    margin-left: 0px;
                    height: 24px;
                    i{
                        align-self: center;
                        font-size: 16px;           
                    }
                }

                input{
                    border:1px solid #E0E0E0;
                    padding: 0px 10px 0px 10px;
                    width: 180px;
                    font-size: $defaultFontSize !important;
                    height: 24px;
                }
            }
            
        }
    }

    .counterTemplateBodyWrapper{
        @extend .fullWidthDiv;
        padding: 10px 10px 70px 10px;

        .defaultTabItemBodyWrapper{
            height: 100vh;
        }
    }

    .counterTemplateFooterWrapper{
        @extend .fullWidthDiv;
        padding: 10px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        border-top:1px solid $separatorColor ;
        background-color: $white;
    }
 }