/**
 * @Author: Chanaka Wickramasinghe
 * @Date: 2020-02-25 11:30:15
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-07-14 12:12:20
 */

// Include Forms _index.scss
@import './form/index';

// Inlude Common _index.scss
@import './common/index';

// Include DialogPopup scss file
@import './modal/dialogPopup';

// Include lists-and-menus scss file
@import './lists-and-menus/listsAndMenus';

// Include ExpansionCard scss file
@import './expansion-panel/expansionCard';

// Include Tabs scss file
@import './tab/tab';

@import './data-table/data_table';

//Upload scss file
@import './upload/upload';
@import './upload/upload-component';


.testformBtn{
    .defaultInputBtnStyle{
        padding: 7px  5px  7px  5px !important;
        margin-left: 3px;
    }
}