/**
 * @Author: Chanaka Wickramasinghe
 * @Description: InputBox styles
 * @Date: 2020-02-25 11:26:55
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-14 10:40:13
 */

// Material InputBox css class
 .materialInputWrapper {   

    .materialInputText {
        font-size: $defaultFontSize !important;
    }
 }

 // Bootstrap InputBox css class
 .bootstrapInputWrapper {

    .defaultInputLabel{
        font-size: $defaultInputLabel !important;
    }
   
    .bootstrapInputText {
        border: 1px solid $geyserColor;
        border-radius: .25rem;
        font-size: $defaultFontSize !important;
        font-weight: 400;
        padding: 4px 7px 5px 7px !important;
        height: auto !important;

        &:before {
            border-bottom: none !important;
        }

        &:after {
            border-bottom: none !important;
        }

        input {
            padding: 0px !important;
            margin-top: 1px !important;
        }
    }
 }

// Bootstrap InputBox css class
//  .bootstrapInputWrapper{

//     label {
//         font-size: $defaultLabel !important;
//         font-weight: 400;
//         margin-top: 0px;
//         margin-bottom: 1px !important;
//         color: $nobel;
//     }

//     span {
//         color: $red;
//         margin-left: 2px;
//     }

//     input {
        // font-size: $defaultFontSize !important;
        // font-weight: 400;
        // padding: 4px 7px;
        // height: auto !important;
//     }

//     textarea {
//         @extend input
//     }

//     .form-control:focus {
//         box-shadow: none !important;
//     }

//     small {
//         font-size: 11px !important;
//         margin-top: 5.45px !important;
//         line-height: 1em;
//         font-weight: 400;
//         min-height: 11px;
//     }
//  }

// .horizontalInput{
//     width: 100%;
//     float: left;

//     label{
//         font-size: 14px;
//         letter-spacing:normal;
//     }

//     .inptutElementWrapper{
//         input{
//             width: 100%;
//             padding: 3px 6px 3px 6px !important;
//             height: auto;
//         }
//         .helperText{
//             height: 16px;
//             font-size: 11.5px;
//             color: $menuLinkLightGray;
//         }
//         .errorTxt{
//             color: red !important;
//         }
//     }
// }

// .testCardHead{
//     font-style: 18px;
//     margin-bottom: 15px;
// }

// .hrTest1{
//  margin-top: 0px !important;
//  margin-bottom: 15px !important;
// }

