.removeContent{
    min-width: 500px;  
}

.textContent{
    //float: right;
    text-align: right !important;
    font-weight: bold;
    padding-right: 17px;
    padding-top: 6px;
}
