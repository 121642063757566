/*
 * @Author: Chanaka Wickramasinghe
 * @Description: Image/File Upload
 * @Date: 2020-06-24 13:24:56
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2020-06-24 13:24:56
 */

.defaultUploadComponentWrapper {

  .defaultUploadSlider {

  }

  .defaultInputErrorText {
    color: $red;
  }

  label {
    font-size: 16px !important;
  }
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;

  input {
    display: none;
  }

  .btn {
    width: 150px !important;
    height: 175px !important;
    background: $nobel;
    border: 5px solid #dee2e6;

    i {
      font-size: 30px;
      margin-top: 50px;
    }

    h5 {
      margin-top: 5px;
      font-weight: bold;
      font-size: 15px !important;
    }
  }
}

.display-image {
  margin-right: 5px !important;
  position: relative;

  img {
    height: 175px !important;
  }
}

.image-hover {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;

  i {
    font-size: 23px;
    cursor: pointer;
  }
}

.display-image:hover .select-image {
  opacity: 0.5;
}

.display-image:hover .image-hover {
  opacity: 1;
}
