/*
 * @Author: Chanaka Wickramasinghe
 * @Description: InputSwitch Styles
 * @Date: 2020-02-27 16:05:35
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2020-02-27 16:05:35
 */

// Material InputSwitch css class
.materialInputSwitchWrapper {

    .defaultInputSwitchLabel {
        font-size: $defaultFontSize !important;
    }

    .inputSwitchErrorText {
        color: $red !important;
    }
}
