/*
 * @Author: Chanaka Wickramasinghe
 * @Description: ExpansionCard styles
 * @Date: 2020-02-28 16:57:21
 * @Last Modified by: Chanaka Wickramasinghe
 * @Last Modified time: 2020-02-28 16:57:21
 */

.materialExpansionPanelWrapper {

    .defaultExpansionBar {

        padding-left: $defaultPadding !important;
        padding-right: $defaultPadding !important;

        h4 {
            font-size: 15px !important;
        }
    }

    .defaultExpansionContent {

        padding-left: $defaultPadding !important;
        padding-right: $defaultPadding !important;
    }
}