.error-overlay {
  position: fixed;
  background: $nobel;
  top: 0;
  bottom: 0;
}

.error-card {
  margin-top: 200px;
  background: $bodyColor;

  h1 {
    color: $darkBlue_1;
  }

  h6 {
    color: $battleshipGreyColor;
  }
}

.errorBackGround {
  width: 100%;
  position: fixed;
  background: #FB665A;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .itemWrapper {
    text-align: center;
    .buttonColor {
      background-color: #FF0000 !important;
    }
    h1 {
      color: #ffffff;
    }
    h6 {
      color: #ffffff;
      text-decoration: none !important;
      :hover {
        text-decoration: none !important;
        font-size: 15px;
      }
    }
  }
}